import React, { useState } from 'react';
import Spline from '@splinetool/react-spline';

export default function App() {
    const [hoverLeft, setHoverLeft] = useState(false);
    const [hoverRight, setHoverRight] = useState(false);
    const [hoverSocial, setHoverSocial] = useState({ telegram: false, twitter: false, dexScanner: false });

    return (
        <div style={{ width: '100vw', height: '100vh', position: 'relative' }}>
            <Spline 
                scene="https://prod.spline.design/SmQTbfReBftSM-5u/scene.splinecode" 
                style={{
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}
            />
            {/* Button on the center-left */}
            <div style={{
                position: 'absolute',
                top: '50%',
                left: '100px',
                transform: 'translateY(-100%) translateY(-25px)',
                display: 'flex',
                alignItems: 'center',
            }}>
                <img src="https://i.imgur.com/DVLKaMW.png" alt="Button Left"
                    onMouseEnter={() => setHoverLeft(true)}
                    onMouseLeave={() => setHoverLeft(false)}
                    style={{
                        width: hoverLeft ? '400px' : '320px',
                        height: hoverLeft ? '440px' : '352px',
                        transition: 'all 0.3s ease-in-out'
                    }} />
            </div>

            {/* Button on the center-right */}
            <div style={{
                position: 'absolute',
                top: '50%',
                right: '300px',
                transform: 'translateY(-100%) translateY(50px)',
                display: 'flex',
                alignItems: 'center',
            }}>
                <img src="https://i.imgur.com/7t8CUfj.png" alt="Button Right"
                    onMouseEnter={() => setHoverRight(true)}
                    onMouseLeave={() => setHoverRight(false)}
                    style={{
                        width: hoverRight ? '520px' : '415px',
                        height: hoverRight ? '572px' : '456px',
                        transition: 'all 0.3s ease-in-out'
                    }} />
            </div>

            {/* Social media icons and static message at the bottom right, with links */}
            <div style={{
                position: 'absolute',
                bottom: '20px',
                right: '20px',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center'
            }}>
                <div style={{ color: 'black', fontSize: '18px', marginRight: '10px' }}>
                    "The token contract will be available soon, for now interact with the cat!"
                </div>
                {/* Icons with hover effects */}
                <a href="https://t.me/gegecat" target="_blank" rel="noopener noreferrer">
                    <img src="https://i.imgur.com/iUAn5xK.png" alt="Telegram"
                        onMouseEnter={() => setHoverSocial(prev => ({ ...prev, telegram: true }))}
                        onMouseLeave={() => setHoverSocial(prev => ({ ...prev, telegram: false }))}
                        style={{
                            width: hoverSocial.telegram ? '46px' : '42px',
                            height: hoverSocial.telegram ? '46px' : '42px',
                            marginRight: '15px',
                            transition: 'all 0.3s ease'
                        }} />
                </a>
                <a href="https://twitter.com/Gegecat764800" target="_blank" rel="noopener noreferrer">
                    <img src="https://i.imgur.com/8WcsVHF.png" alt="Twitter"
                        onMouseEnter={() => setHoverSocial(prev => ({ ...prev, twitter: true }))}
                        onMouseLeave={() => setHoverSocial(prev => ({ ...prev, twitter: false }))}
                        style={{
                            width: hoverSocial.twitter ? '42px' : '38px',
                            height: hoverSocial.twitter ? '42px' : '38px',
                            marginRight: '15px',
                            transition: 'all 0.3s ease'
                        }} />
                </a>
                <a href="https://dexscreener.com/" target="_blank" rel="noopener noreferrer">
                    <img src="https://i.imgur.com/v5iHUQX.png" alt="DexScanner"
                        onMouseEnter={() => setHoverSocial(prev => ({ ...prev, dexScanner: true }))}
                        onMouseLeave={() => setHoverSocial(prev => ({ ...prev, dexScanner: false }))}
                        style={{
                            width: hoverSocial.dexScanner ? '44px' : '40px',
                            height: hoverSocial.dexScanner ? '44px' : '40px',
                            transition: 'all 0.3s ease'
                        }} />
                </a>
            </div>
        </div>
    );
}
